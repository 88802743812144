/* amatic-sc-700 - latin-ext */
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Amatic SC Bold'), local('AmaticSC-Bold'),
       url('../fonts/amatic-sc-v15-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/amatic-sc-v15-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* k2d-regular - latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('K2D Regular'), local('K2D-Regular'),
       url('../fonts/k2d-v4-latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/k2d-v4-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* k2d-italic - latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('K2D Italic'), local('K2D-Italic'),
       url('../fonts/k2d-v4-latin-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/k2d-v4-latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* k2d-700 - latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('K2D Bold'), local('K2D-Bold'),
       url('../fonts/k2d-v4-latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/k2d-v4-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* k2d-700italic - latin-ext */
@font-face {
  font-family: 'K2D';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('K2D Bold Italic'), local('K2D-BoldItalic'),
       url('../fonts/k2d-v4-latin-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/k2d-v4-latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

